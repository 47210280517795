<template>
  <ValidationObserver
    ref="observer"
    v-slot="{ validate, invalid }"
    tag="form"
    @submit.prevent="handleSubmit"
  >
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">Пароль</p>
      </header>

      <section class="modal-card-body">
        <div class="columns is-vcentered">
          <div class="column">
            <ValidationProvider
              rules="required"
              name="Старый пароль"
              v-slot="{ errors, valid }"
              slim
            >
              <b-field
                label="Старый пароль"
                :type="{ 'is-danger': errors[0], 'is-success': valid }"
                :message="errors"
              >
                <b-input
                  v-model="oldPassword"
                  type="password"
                  name="old_password"
                />
              </b-field>
            </ValidationProvider>

            <ValidationProvider
              rules="required|min:12|strongPassword"
              name="Новый пароль"
              v-slot="{ errors, valid }"
              slim
              vid="Пароль"
            >
              <b-field
                label="Новый пароль"
                :type="{ 'is-danger': errors[0], 'is-success': valid }"
                :message="errors"
              >
                <b-input v-model="password" type="password" />
              </b-field>
            </ValidationProvider>

            <ValidationProvider
              rules="required|confirmed:Пароль"
              name="Повтор пароля"
              v-slot="{ errors, valid }"
              slim
            >
              <b-field
                label="Повтор пароля"
                :type="{ 'is-danger': errors[0], 'is-success': valid }"
                :message="errors[0] ? 'Пароли не совпадают' : null"
              >
                <b-input v-model="passwordRepeat" type="password" />
              </b-field>
            </ValidationProvider>
          </div>
        </div>
      </section>

      <footer class="modal-card-foot">
        <AppFormButtons
          :is-loading="isLoading"
          :is-show-reset="false"
          :is-submit-disabled="invalid"
          @send="validate().then(handleSubmit)"
          @close="$emit('close', { id: null })"
        />
      </footer>
    </div>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import AppFormButtons from "@/common/components/AppFormButtons";
import { Resource } from "@/common/const/common";
import { SAVE_ERROR, SAVE_SUCCESS } from "@/common/const/message";

export default {
  name: "UserPasswordForm",

  components: { AppFormButtons, ValidationProvider, ValidationObserver },

  data() {
    return {
      isLoading: false,
      oldPassword: "",
      password: "",
      passwordRepeat: "",
    };
  },

  methods: {
    async handleSubmit() {
      try {
        this.isLoading = true;
        const data = {
          oldPassword: this.oldPassword,
          password: this.password,
          passwordRepeat: this.passwordRepeat,
        };

        const { jwt } = await this.$api[Resource.AUTH].changePassword(data);
        await this.$jwt.saveToken(jwt);
        await this.$api.auth.setAuthHeader(jwt);

        this.$notifier.success(SAVE_SUCCESS);
        this.$emit("close");
      } catch (e) {
        console.log(e);
        this.$notifier.error(e.response?.data?.message || SAVE_ERROR);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
