<template>
  <ValidationObserver
    ref="observer"
    v-slot="{ validate, invalid }"
    tag="form"
    @submit.prevent="handleSubmit"
  >
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">Фото</p>
      </header>

      <section class="modal-card-body">
        <ValidationProvider
          v-slot="{ errors, valid }"
          rules="ext:jpeg,jpg,webp|size:150"
          name="Фото"
          slim
        >
          <b-field
            class="file is-primary"
            :class="{ 'has-name': !!file, 'is-danger': !valid }"
          >
            <b-upload
              v-model="file"
              class="file-label"
              @input="isChange = true"
            >
              <span class="file-cta">
                <b-icon class="file-icon" icon="upload" />
                <span class="file-label">Выбрать фото</span>
              </span>
              <span v-if="file && valid" class="file-name">
                {{ file.name }}
              </span>
              <span v-if="errors[0]" class="ml-2 has-text-danger is-size-7">{{
                errors[0]
              }}</span>
            </b-upload>
          </b-field>
        </ValidationProvider>

        <b-image
          v-if="imageSrc"
          class="image mt-3"
          ratio="3by2"
          :src="imageSrc"
        />
        <div v-else class="image-stub" />
      </section>

      <footer class="modal-card-foot">
        <div class="buttons mt-2">
          <b-button
            type="is-success"
            icon-left="check"
            :loading="loading"
            :disabled="invalid || !isChange"
            @click="validate().then(handleSubmit)"
          >
            Сохранить
          </b-button>

          <b-button
            :disabled="isDeleteDisabled"
            :loading="removeLoading"
            icon-left="close"
            @click="handleDelete"
          >
            Удалить
          </b-button>
        </div>
      </footer>
    </div>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mapGetters } from "vuex";
import { Resource } from "@/common/const/common";
import { SAVE_ERROR, SAVE_SUCCESS } from "@/common/const/message";

export default {
  name: "UserAvatarForm",

  components: { ValidationProvider, ValidationObserver },

  data() {
    return {
      removeLoading: false,
      loading: false,
      isChange: false,
      file: null,
      avatar: null,
    };
  },

  computed: {
    ...mapGetters({
      user: "auth/getUser",
    }),

    isDeleteDisabled() {
      return !this.avatar;
    },

    imageSrc() {
      if (!this.file) {
        return null;
      }
      if (this.file !== this.avatar) {
        return URL.createObjectURL(this.file);
      }

      return this.avatar ? process.env.VUE_APP_API_URL + this.avatar.url : null;
    },
  },

  created() {
    this.file = this.user.avatar;
    this.avatar = this.user.avatar;
  },

  async beforeDestroy() {
    this.file = null;
  },

  methods: {
    async handleDelete() {
      try {
        this.removeLoading = true;
        const data = await this.$api[Resource.AUTH].deleteAvatar();
        await this.$store.dispatch("auth/setMe", data);
        this.$notifier.success(SAVE_SUCCESS);
      } catch (e) {
        console.log(e);
      } finally {
        this.removeLoading = false;
        this.$emit("close");
      }
    },
    async handleSubmit() {
      try {
        this.loading = true;
        const formData = new FormData();

        if (this.file !== this.avatar && this.file instanceof Blob) {
          formData.append("files.avatar", this.file, this.file.name);
        }
        formData.append("data", "{}");

        const data = await this.$api[Resource.AUTH].updateAvatar(formData);
        await this.$store.dispatch("auth/setMe", data);
      } catch (e) {
        console.log(e);
        this.$notifier.error(SAVE_ERROR);
      } finally {
        this.loading = false;
        this.$emit("close");
      }
    },
  },
};
</script>
