<template>
  <div class="container">
    <div class="columns is-centered is-multiline">
      <div class="column column is-one-quarter-desktop">
        <b-menu>
          <!--          <b-menu-list label="Сервис" v-if="isManager">-->
          <!--            <b-menu-item-->
          <!--              @click="handleReportResults"-->
          <!--              label="Отчет по результатам"-->
          <!--              :active.sync="reportActive"-->
          <!--            >-->
          <!--            </b-menu-item>-->
          <!--          </b-menu-list>-->
          <b-menu-list label="Профиль">
            <b-menu-item
              label="Редактировать"
              @click="handleUpdate"
              :active.sync="updateActive"
            ></b-menu-item>
            <b-menu-item
              label="Сменить фото"
              @click="handleEditAvatar"
              :active.sync="avatarActive"
            ></b-menu-item>
            <b-menu-item
              label="Сменить пароль"
              @click="handleChangePass"
              :active.sync="changePassActive"
            ></b-menu-item>
          </b-menu-list>
        </b-menu>
      </div>

      <div
        class="column is-one-quarter-desktop hoverable"
        style="position: relative"
        @click="handleEditAvatar"
      >
        <figure v-if="user.avatar" class="image is3by2">
          <img :src="getImgUrl(user.avatar)" alt="avatar" />
        </figure>

        <div v-else class="image-stub-profile" title="Загрузить аватар"></div>
      </div>

      <div class="column">
        <div class="block">
          <p class="title is-size-4">
            {{ user.lastname }} {{ user.firstname }}
          </p>
          <p v-if="user.position">
            {{ user.position }}
          </p>
        </div>

        <!--        <div class="buttons">-->
        <!--          <b-button type="is-info" icon-left="pencil" @click="handleUpdate"-->
        <!--            >Редактировать</b-button-->
        <!--          >-->
        <!--          <b-button type="is-light" @click="handleChangePass"-->
        <!--            >Сменить пароль</b-button-->
        <!--          >-->
        <!--        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getImgUrl } from "@/common/utils/common";
import UserProfileForm from "@/modules/user/components/UserProfileForm";
import UserPasswordForm from "@/modules/user/components/UserPasswordForm";
import UserAvatarForm from "@/modules/user/components/UserAvatarForm";
import UserReportResultsForm from "@/modules/user/components/UserReportResultsForm";

export default {
  name: "UserProfile",

  data() {
    return {
      isActive: false,
      reportActive: false,
      changePassActive: false,
      updateActive: false,
      avatarActive: false,
    };
  },

  computed: {
    ...mapGetters({
      authUser: "auth/getUser",
    }),
    user() {
      return this.authUser
        ? this.authUser
        : { lastname: "", firstname: "", position: null, avatar: null };
    },
    isManager() {
      return this.authUser?.isManager || false;
    },
  },

  methods: {
    handleReportResults() {
      const modal = this.$buefy.modal.open({
        parent: this,
        component: UserReportResultsForm,
        hasModalCard: true,
        fullScreen: false,
      });
      modal.$on("close", () => {
        this.reportActive = false;
      });
    },

    async handleChangePass() {
      const modal = this.$buefy.modal.open({
        parent: this,
        component: UserPasswordForm,
        hasModalCard: true,
      });
      modal.$on("close", () => {
        this.changePassActive = false;
      });
    },

    async handleUpdate() {
      const modal = this.$buefy.modal.open({
        parent: this,
        component: UserProfileForm,
        hasModalCard: true,
        trapFocus: true,
      });
      modal.$on("close", () => {
        this.updateActive = false;
      });
    },

    async handleEditAvatar() {
      const modal = this.$buefy.modal.open({
        parent: this,
        component: UserAvatarForm,
        hasModalCard: true,
      });
      modal.$on("close", () => {
        this.avatarActive = false;
      });
    },

    getImgUrl,
  },
};
</script>
