var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observer",attrs:{"tag":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
var invalid = ref.invalid;
return [_c('div',{staticClass:"modal-card",staticStyle:{"width":"auto"}},[_c('header',{staticClass:"modal-card-head"},[_c('p',{staticClass:"modal-card-title"},[_vm._v("Профиль пользователя")])]),_c('section',{staticClass:"modal-card-body"},[_c('ValidationProvider',{attrs:{"rules":"required|min:2","name":"Фамилия","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"label":"Фамилия","type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors}},[_c('b-input',{attrs:{"type":"text"},model:{value:(_vm.lastname),callback:function ($$v) {_vm.lastname=$$v},expression:"lastname"}})],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required|min:2","name":"Имя","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"label":"Имя","type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors}},[_c('b-input',{attrs:{"type":"text"},model:{value:(_vm.firstname),callback:function ($$v) {_vm.firstname=$$v},expression:"firstname"}})],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"min:2","name":"Должность","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"label":"Должность","type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors}},[_c('b-input',{attrs:{"type":"text"},model:{value:(_vm.position),callback:function ($$v) {_vm.position=$$v},expression:"position"}})],1)]}}],null,true)})],1),_c('footer',{staticClass:"modal-card-foot"},[_c('AppFormButtons',{attrs:{"is-loading":_vm.loading,"is-show-reset":false,"is-submit-disabled":invalid || _vm.isDisabled},on:{"send":function($event){validate().then(_vm.handleSubmit)},"close":function($event){return _vm.$emit('close', { id: null })}}})],1)])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }