<template>
  <section class="section">
    <UserProfile />
  </section>
</template>

<script>
import UserProfile from "@/modules/user/components/UserProfile";
export default {
  name: "User",
  components: { UserProfile },
};
</script>
