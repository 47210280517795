<template>
  <ValidationObserver
    ref="observer"
    v-slot="{ validate, invalid }"
    tag="form"
    @submit.prevent="handleSubmit"
  >
    <div class="modal-card" style="width: auto; height: 100vh">
      <header class="modal-card-head">
        <p class="modal-card-title">Отчет по результатам</p>
      </header>

      <section class="modal-card-body">
        <div class="columns">
          <ValidationProvider
            v-slot="{ errors, valid }"
            tag="div"
            class="column is-half-desktop"
            rules="required"
            name="Дата начала"
            vid="dateStart"
          >
            <b-field
              label="Дата начала"
              :message="errors[0]"
              :type="{ 'is-danger': errors[0], 'is-success': valid }"
            >
              <b-datepicker
                v-model="dateStart"
                placeholder="Выберите дату начала"
                icon="calendar-today"
                locale="ru-Ru"
              >
                >
              </b-datepicker>
            </b-field>
          </ValidationProvider>

          <ValidationProvider
            v-slot="{ errors, valid }"
            tag="div"
            class="column is-half-desktop"
            rules="required|isDateGte:dateStart"
            name="Дата окончания"
          >
            <b-field
              label="Дата окончания"
              :message="errors[0]"
              :type="{ 'is-danger': errors[0], 'is-success': valid }"
            >
              <b-datepicker
                v-model="dateFinish"
                placeholder="Выберите дату окончания"
                icon="calendar-today"
                locale="ru-Ru"
              >
                >
              </b-datepicker>
            </b-field>
          </ValidationProvider>
        </div>
      </section>

      <footer class="modal-card-foot">
        <AppFormButtons
          save-title="Получить"
          :is-loading="loading"
          :is-show-reset="false"
          :is-submit-disabled="invalid"
          @send="validate().then(handleSubmit)"
          @close="$emit('close')"
        />
      </footer>
    </div>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mapGetters } from "vuex";
import AppFormButtons from "@/common/components/AppFormButtons";
import { DATE_FORMAT, Resource } from "@/common/const/common";
import { SAVE_ERROR, SAVE_SUCCESS } from "@/common/const/message";

export default {
  name: "UserReportResultsForm",

  components: { AppFormButtons, ValidationProvider, ValidationObserver },

  data() {
    return {
      isChanged: false,
      loading: false,
      dateStart: null,
      dateFinish: null,
      filename: "results.xlsx",
    };
  },

  computed: {
    ...mapGetters({
      user: "auth/getUser",
    }),
  },

  methods: {
    async handleSubmit() {
      const start = this.$dayjs(this.dateStart).format(DATE_FORMAT);
      const finish = this.$dayjs(this.dateFinish).format(DATE_FORMAT);

      try {
        this.loading = true;

        // const uri =
        //   process.env.VUE_APP_API_URL +
        //   `/reports/result?start=${start}&finish=${finish}`;

        const uri =
          process.env.VUE_APP_API_URL +
          `/reports/events-report?start=${start}&finish=${finish}`;

        const jwt = this.$jwt.getToken();
        const response = await fetch(uri, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        });
        const data = await response.blob();
        const url = window.URL.createObjectURL(data);
        const link = document.createElement("a");

        link.href = url;
        link.download = this.filename;

        link.click();
        window.URL.revokeObjectURL(url);
      } catch (e) {
        console.log(e);
        this.$notifier.error(SAVE_ERROR);
      } finally {
        this.loading = false;
        this.$emit("close");
      }
    },
  },
};
</script>
