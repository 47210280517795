<template>
  <ValidationObserver
    ref="observer"
    v-slot="{ validate, invalid }"
    tag="form"
    @submit.prevent="handleSubmit"
  >
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">Профиль пользователя</p>
      </header>

      <section class="modal-card-body">
        <ValidationProvider
          rules="required|min:2"
          name="Фамилия"
          v-slot="{ errors, valid }"
          slim
        >
          <b-field
            label="Фамилия"
            :type="{ 'is-danger': errors[0], 'is-success': valid }"
            :message="errors"
          >
            <b-input v-model="lastname" type="text" />
          </b-field>
        </ValidationProvider>

        <ValidationProvider
          rules="required|min:2"
          name="Имя"
          v-slot="{ errors, valid }"
          slim
        >
          <b-field
            label="Имя"
            :type="{ 'is-danger': errors[0], 'is-success': valid }"
            :message="errors"
          >
            <b-input v-model="firstname" type="text" />
          </b-field>
        </ValidationProvider>

        <ValidationProvider
          rules="min:2"
          name="Должность"
          v-slot="{ errors, valid }"
          slim
        >
          <b-field
            label="Должность"
            :type="{ 'is-danger': errors[0], 'is-success': valid }"
            :message="errors"
          >
            <b-input v-model="position" type="text" />
          </b-field>
        </ValidationProvider>
      </section>

      <footer class="modal-card-foot">
        <AppFormButtons
          :is-loading="loading"
          :is-show-reset="false"
          :is-submit-disabled="invalid || isDisabled"
          @send="validate().then(handleSubmit)"
          @close="$emit('close', { id: null })"
        />
      </footer>
    </div>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mapGetters } from "vuex";
import AppFormButtons from "@/common/components/AppFormButtons";
import { Resource } from "@/common/const/common";
import { SAVE_ERROR, SAVE_SUCCESS } from "@/common/const/message";

export default {
  name: "UserProfileForm",

  components: { AppFormButtons, ValidationProvider, ValidationObserver },

  data() {
    return {
      isChanged: false,
      loading: false,
      lastname: null,
      firstname: null,
      position: null,
      edited: {},
    };
  },

  computed: {
    ...mapGetters({
      user: "auth/getUser",
    }),

    isDisabled() {
      return !(
        this.lastname.trim() !== this.user.lastname ||
        this.firstname.trim() !== this.user.firstname ||
        this.position?.trim() !== this.user.position
      );
    },
  },

  created() {
    this.lastname = this.user.lastname;
    this.firstname = this.user.firstname;
    this.position = this.user.position || null;
  },

  methods: {
    async handleSubmit() {
      try {
        this.loading = true;

        const payload = {
          lastname: this.lastname,
          firstname: this.firstname,
          position: this.position,
        };

        const data = await this.$api[Resource.AUTH].update(payload);
        await this.$store.dispatch("auth/setMe", data);
        this.$notifier.success(SAVE_SUCCESS);
      } catch (e) {
        console.log(e);
        this.$notifier.error(SAVE_ERROR);
      } finally {
        this.loading = false;
        this.$emit("close");
      }
    },
  },
};
</script>
